import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { auth } from './services/session';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.prorrac.com.br">
        Prorrac
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(img/wallpaper2-min.PNG)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    paddingTop: "40px",
  },
  paper: {
    margin: theme.spacing(8, 8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export function SignIn() {
  const classes = useStyles();
  const history = useHistory();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required('Campo requerido'),
    password: Yup.string()
      .required('Campo requerido'),
  });

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid container justify="center" className={classes.image}>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          direction="row"
          elevation={6}
          square
        >
          <Grid className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Acesso
            </Typography>
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={validationSchema}
              onSubmit={async values => {

                const result = await auth({ ...values });
                if (result !== false) {
                  if (!result.administrator) {
                    history.push("/home");
                  } else if (result.userLimit === -1) {
                    const permited = !result.attributes.painel_access ? false : result.attributes.painel_access === 'true' ? true : false;
                    if (permited) {
                      history.push("/home");
                    } else {
                      alert('Acesso não autorizado!');
                    }
                  }

                } else {
                  alert('Acesso não autorizado!');
                }
              }}>

              {({ errors, touched, values, handleChange, handleSubmit }) => (
                <Form>
                  <form className={classes.form}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={values.email}
                      onChange={handleChange}
                      className={errors.email && "input-error"}
                    />
                    {errors.email && touched.email ? (
                      <span style={{ color: 'red' }}>{errors.email} <br /></span>
                    ) : null}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={values.password}
                      onChange={handleChange}
                      className={errors.password && "input-error"}
                    />
                    {errors.password && touched.password ? (
                      <span style={{ color: 'red' }}>{errors.password} <br /></span>
                    ) : null}

                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Lembrar"
                    />
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      type="button"
                      onClick={() => {
                        handleSubmit()
                      }}
                    >
                      Acessar
                      </Button>

                    <Box mt={5}>
                      <Copyright />
                    </Box>
                  </form>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
