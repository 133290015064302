import api from '../api';
import { writeStorage, deleteStorage } from '../storage';

export const auth = async ({ email, password }) => {

  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);

  try {
    const result = await api.post(`/session`, params);

    writeStorage('session', {
      ...result.data,
      password
    });

    return result.data;

  } catch (error) {
    return false;
  }

}

export const logout = async () => {
  deleteStorage('session');
}
