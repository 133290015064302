import React, { Component, createRef } from "react";

import L from 'leaflet';

import MarkerClusterGroup from 'react-leaflet-markercluster';

import Control from 'react-leaflet-custom-control';
import { MapContainer as LeafletContainer, TileLayer, Marker, Popup, LayersControl, Tooltip } from 'react-leaflet'

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css'; // inside .js file

import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { DataGrid, GridToolbarDensitySelector } from '@mui/x-data-grid';
import { withRouter } from 'react-router-dom';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/RemoveCircle";
import InputLabel from '@material-ui/core/InputLabel';
import ClearIcon from "@material-ui/icons/Clear";
import TagIcon from "@material-ui/icons/Label";
import ExitIcon from "@material-ui/icons/ExitToApp";
import SearchIcon from "@material-ui/icons/Search";
//import UploadIcon from "@material-ui/icons/CloudUpload";
//import DownloadIcon from "@material-ui/icons/CloudDownload";
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createTheme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import moment from "moment";

import getPin from '../Components/CategoryIcons';

import {
  insertServer, deleteServer, getServers, getAllGroups,
  getAllDevices, getAllPositions, sendCommand
} from '../services/server';
import { deleteStorage, readString, writeString } from "../services/storage";


//Ajustes Jackson
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';


let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const styles = {
  formControl: {
    margin: 0,
  },
  selectEmpty: {
    marginTop: 2,
  },
};

const defaultTheme = createTheme();
const useStyles = makeStyles(
  (theme) =>
    createStyles({
      root: {
        padding: theme.spacing(0.5, 0.5, 0),
        justifyContent: 'space-between',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      },
      textField: {
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
        margin: theme.spacing(1, 0.5, 1.5),
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(0.5),
        },
        '& .MuiInput-underline:before': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },
      },
    }),
  { defaultTheme },
);

function QuickSearchToolbar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <GridToolbarDensitySelector placeholder="Densidade" />
      </div>
      <TextField
        variant="standard"
        value={props.value}
        onChange={props.onChange}
        placeholder="Search…"
        className={classes.textField}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <Button
              title="Clear"
              aria-label="Clear"
              size="small"
              style={{ visibility: props.value ? 'visible' : 'hidden' }}
              onClick={props.clearSearch}
            >
              <ClearIcon fontSize="small" />
            </Button>
          ),
        }}
      />
    </div>
  );
}


class MapContainer extends Component {

  mapRef = createRef();

  constructor(props) {
    super(props);


    this.state = {
      page: {
        width: 0,
        height: 0,
      },
      classes: this.props,
      loading: true,
      servers: {
        current: '',
        data: [],
        openDialogNew: false,
        openDialogDelete: false,
      },
      positions: {
        data: [],
        allData: [],
        outerBounds: [
          [7.684407, -77.627080],
          [-40.334091, -29.948018],
        ],
      },
      devices: {
        data: [],
        allData: [],
      },
      groups: {
        data: [],
      },
      dialogLock: {
        open: false,
        title: '',
        message: '',
        deviceId: 0,
        command: '',
        server: '',
      },
      searchValue: '',
      showLabels: false
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

  }

  componentDidMount = async () => {

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    const result = await getServers();

    const showLabels = await readString('showLabels');

    this.setState({
      ...this.state,
      showLabels: !showLabels ? false : showLabels === '1' ? true : false,
    });

    await this.updateData(true);

    var intervalId = setInterval(async () => {
      await this.updateData(false);
    }, 30000);

    this.setState({
      ...this.state,
      intervalId,
      servers: {
        ...this.state.servers,
        data: result
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    clearInterval(this.state.intervalId);
  }

  updateData = async (new_server) => {

    if (new_server) {
      const allGroups = await getAllGroups();

      this.setState({
        ...this.state,
        groups: {
          ...this.state.groups,
          data: allGroups,
          allData: allGroups,
        }
      });
    }

    const allDevices = await getAllDevices();

    const allPositions = await getAllPositions();

    this.setState({
      ...this.state,
      positions: {
        ...this.state.positions,
        data: allPositions,
        allData: allPositions
      },
      devices: {
        ...this.state.devices,
        data: allDevices,
        allData: allDevices,
      },
      loading: false,
    });
  }


  updateWindowDimensions() {
    this.setState({ page: { width: window.innerWidth, height: window.innerHeight } });
  }

  handleClickNewServerOpen = () => {
    this.setState({
      ...this.state,
      servers: {
        ...this.state.servers,
        openDialogNew: true
      }
    });
  };

  handleClickNewServerClose = () => {
    this.setState({
      ...this.state,
      servers: {
        ...this.state.servers,
        openDialogNew: false
      }
    });
  };

  handleClickDeleteServerOpen = () => {
    if (this.state.servers.current !== 'all') {
      this.setState({
        ...this.state,
        servers: {
          ...this.state.servers,
          openDialogDelete: true
        }
      });
    }
  };

  handleClickDeleteServerClose = () => {
    this.setState({
      ...this.state,
      servers: {
        ...this.state.servers,
        openDialogDelete: false
      }
    });
  };

  handleClickDeleteServerExecute = async () => {

    const result = await deleteServer(this.state.servers.current);

    this.setState({
      ...this.state,
      servers: {
        ...this.state.servers,
        data: result,
        openDialogDelete: false
      }
    });

    await this.updateData(true);
  };

  handleSelectChange = (event) => {

    this.setState({
      ...this.state,
      loading: true,
    });

    let filteredDevices = [];
    let filteredPositions = [];

    if (event.target.value !== 'all') {
      filteredDevices = this.state.devices.allData.filter(function (el) {
        return el.server === event.target.value
      });
      filteredPositions = this.state.positions.allData.filter(a => filteredDevices.some(b => a.deviceId === b.id));
    } else {
      filteredDevices = this.state.devices.allData;
      filteredPositions = this.state.positions.allData;
    }

    this.setState({
      ...this.state,
      servers: {
        ...this.state.servers,
        current: event.target.value
      },
      devices: {
        ...this.state.devices,
        data: filteredDevices
      },
      positions: {
        ...this.state.positions,
        data: filteredPositions
      },
      loading: false,
    });
  };

  handleCloseLoading = () => {
    this.setState({
      ...this.state,
      loading: false
    });
  }

  handleClickLockDialogOpen = (title, message, server, deviceId, command) => {
    this.setState({
      ...this.state,
      dialogLock: {
        ...this.state.dialogLock,
        open: true,
        title,
        message,
        command,
        server,
        deviceId,
      }
    });
  };

  handleClickLockDialogClose = () => {
    this.setState({
      ...this.state,
      dialogLock: {
        ...this.state.dialogLock,
        open: false
      }
    });
  };

  handleClickLockDialogExecute = async () => {

    await sendCommand({ ...this.state.dialogLock });

    this.handleClickLockDialogClose();
  };

  courseFormatter = (value) => {
    var courseValues = ['Norte', 'Nordeste', 'Leste', 'Sudeste', 'Sul', 'Sudoeste', 'Oeste', 'Noroeste'];
    return courseValues[Math.floor(value / 45)];
  };

  speedFormatter = (value) => {
    return (value * 1.852).toFixed(1);
  }

  requestSearch = (searchValue) => {
    this.setState({
      ...this.state,
      searchValue
    });

    const filteredGroups = this.state.groups.data.filter(function (el2) { return (el2.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) })

    const filteredDevices = this.state.devices.allData.filter(function (el) {
      return (el.name && el.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) ||
        (el.uniqueId && el.uniqueId.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) ||
        (el.phone && el.phone.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) ||
        (el.model && el.model.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) ||
        (el.groupId && filteredGroups.some(obj => obj.id === el.groupId))
    });

    const filteredPositions = this.state.positions.allData.filter(a => filteredDevices.some(b => a.deviceId === b.id && a.server === b.server));

    this.setState({
      ...this.state,
      devices: {
        ...this.state.devices,
        data: filteredDevices
      },
      positions: {
        ...this.state.positions,
        data: filteredPositions
      }
    });
  };





  render() {


    const goTo = (id) => {
      const map = this.mapRef;

      this.setState({
        loading: true,
      });

      const idx = this.state.positions.allData.findIndex(p => p.deviceId === id);

      if (idx > -1) {
        map.setView([this.state.positions.allData[idx].latitude, this.state.positions.allData[idx].longitude], 38);
      }

      this.setState({
        loading: false,
      });
      return null;
    }

    const columns = [
      /*   {
           field: 'icon',
           headerName: ' ',
           sortable: false,
           renderCell: (params) => {
   
             const server = this.state.servers.data.findIndex(s=> s.code === params.getValue(params.id, 'server'));
             return (
               <div className="d-flex justify-content-between align-items-center" style={{ cursor: "pointer" }}>
                 <Avatar variant="rounded" alt="?" src={`${server.url}/photos/devices/${params.getValue(params.id, 'photo')}`} />
               </div>
             );
           }
         },*/
      {
        field: 'server',
        headerName: 'Servidor',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const serverIdx = this.state.servers.data.findIndex(p => p.code === params.getValue(params.id, 'server'));
          if (serverIdx > -1) {
            return this.state.servers.data[serverIdx].name;
          }
          return '-';
        },
      },
      {
        field: 'client',
        headerName: 'Cliente',
        sortable: true,
        width: 180,
        renderCell: (params) => {
          const group = getGroupById(params.getValue(params.id, 'groupId'));
          return group ? group.name : '';
        },
      },
      {
        field: 'name',
        headerName: 'Placa',
        width: 150,
        editable: false,
        renderCell: (params) => {
          return <a href="#"
            style={{
              color: 'blue',
              backgroundColor: 'transparent',
              cursor: 'pointer'
            }}
            onClick={() => {
              goTo(params.getValue(params.id, 'id'));
            }}>{params.getValue(params.id, 'name')}</a>;
        },
      },
      {
        field: 'model',
        headerName: 'Modelo',
        width: 150,
        editable: false,
      },
      {
        field: 'contact',
        headerName: 'Observação',
        width: 170,
        editable: false,
      },
      {
        field: 'ignition',
        headerName: 'Ignição',
        sortable: true,
        width: 120,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            const attributes = this.state.positions.data[positionIdx].attributes;
            return attributes.ignition ? attributes.ignition === true ? 'Sim' : 'Não' : '-';
          }
          return '-';
        },
      },
      {
        field: 'speed',
        headerName: 'Velocidade',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return `${this.speedFormatter(this.state.positions.data[positionIdx].speed)} Km/h`
          }
          return '-';
        },
      },
      {
        field: 'fixTime',
        headerName: 'Data Posição',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return moment(this.state.positions.data[positionIdx].fixTime).format('DD/MM/YYYY HH:mm:ss')
          }
          return '-';
        },
      },
      {
        field: 'lastUpdate',
        headerName: 'Ultima Atualização',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const lastUpdate = params.getValue(params.id, 'lastUpdate');

          if (lastUpdate) {
            return moment(lastUpdate).format('DD/MM/YYYY HH:mm:ss')
          }
          return '-';
        },
      },
      {
        field: 'course',
        headerName: 'Direção',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return `${this.courseFormatter(this.state.positions.data[positionIdx].course)}`
          }
          return '-';
        },
      },
      {
        field: 'address',
        headerName: 'Endereço',
        sortable: false,
        width: 200,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return this.state.positions.data[positionIdx].address;
          }
          return '-';
        },
      },
      {
        field: 'latitude',
        headerName: 'Latitude',
        sortable: false,
        width: 120,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return this.state.positions.data[positionIdx].latitude;
          }
          return '-';
        },
      },
      {
        field: 'longitude',
        headerName: 'Longitude',
        sortable: false,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return this.state.positions.data[positionIdx].longitude;
          }
          return '-';
        },
      },
      {
        field: 'locked',
        headerName: 'Bloqueado',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            const attributes = this.state.positions.data[positionIdx].attributes;
            return attributes.locked ? attributes.locked === true ? 'Sim' : 'Não' : '-';
          }
          return '-';
        },
      },
      {
        field: 'protocol',
        headerName: 'Protocolo',
        sortable: true,
        width: 130,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            return this.state.positions.data[positionIdx].protocol.toUpperCase();
          }
          return '-';
        },
      },
      {
        field: 'alarm',
        headerName: 'Alarme',
        sortable: true,
        width: 160,
        renderCell: (params) => {
          const positionIdx = this.state.positions.data.findIndex(p => p.deviceId === params.getValue(params.id, 'id'));
          if (positionIdx > -1) {
            const attributes = this.state.positions.data[positionIdx].attributes;
            return attributes.alarm ? attributes.alarm : '-';
          }
          return '-';
        },
      },
      {
        field: 'uniqueId',
        headerName: 'Identificador',
        width: 150,
        sortable: true,
      },
    ];


    const getUrlByCode = (code) => {
      const idx = this.state.servers.data.findIndex(s => s.code === code);

      if (idx > -1) {
        return this.state.servers.data[idx].url;
      }

      return '';
    }
    const getGroupById = (id) => {

      if (this.state.groups.data.length === 0) {
        return null;
      }

      const idx = this.state.groups.data.findIndex(g => g.id === id);

      if (idx > -1) {
        return this.state.groups.data[idx];
      }

      return null;
    }

    const getGroupNameByDevice = (device) => {

      if (this.state.groups.data.length === 0) {
        return device.groupId;
      }

      const idx = this.state.groups.data.findIndex(g => g.id === device.groupId && g.server === device.server);

      if (idx > -1) {
        return this.state.groups.data[idx].name;
      }

      return device.groupId;
    }

    return (
      <div>
        <Backdrop style={{
          zIndex: 999999,
          color: '#fff',
        }} open={this.state.loading} onClick={this.handleCloseLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <LeafletContainer
          key={0}
          bounds={this.state.positions.outerBounds}
          scrollWheelZoom={false}
          className="markercluster-map"
          zoom={4}
          maxZoom={18}
          whenCreated={(map) => {
            this.mapRef = map;
          }}>
          {/*<TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
        />*/}
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="OpenStreetMap.Padrão">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="OpenStreetMap.Negativo">
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>

            <LayersControl.Overlay name="Google">
              <LayersControl.BaseLayer name='Google Maps Satélite'>
                <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_API_KEY} type={'satellite'} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name='Google Maps Terreno'>
                <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_API_KEY} type={'terrain'} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name='Google Maps Híbrido'>
                <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_API_KEY} type={'hybrid'} />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name='Google Maps Padrão'>
                <ReactLeafletGoogleLayer apiKey={process.env.REACT_APP_GOOGLE_API_KEY} type={'roadmap'} />
              </LayersControl.BaseLayer>

            </LayersControl.Overlay>
          </LayersControl>
          <div style={{
            position: 'absolute',
            left: 100,
            top: 0,
            zIndex: 9999,
            backgroundColor: '#fff',
            borderRadius: 10,
            width: 300,
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: 5
          }}>

            <FormControl className={this.state.classes.formControl} style={{
              flex: 1
            }}>
              <InputLabel shrink htmlFor="age-native-label-placeholder">
                Servidores
            </InputLabel>
              <NativeSelect
                style={{ width: 200 }}
                value={this.state.servers.current}
                onChange={this.handleSelectChange}
                inputProps={{
                  name: 'server',
                  id: 'server-native-label-placeholder',
                }}>
                <option value={`all`}>Todos</option>
                {this.state.servers.data && this.state.servers.data.map(server => {
                  return <option value={`${server.code}`}>{server.name}</option>
                })}
              </NativeSelect>

            </FormControl>
            <Button color='primary' style={{
              marginTop: 5,
            }} onClick={this.handleClickNewServerOpen}>
              <AddIcon />
            </Button>
            <Button color='inherit' style={{
              marginTop: 5,
              color: 'red',
            }} onClick={this.handleClickDeleteServerOpen}>
              <DeleteIcon />
            </Button>
          </div>

          <Control position="topleft" >
            <div style={{ height: 150, width: 45, backgroundColor: '#fff', borderRadius: 5 }}>
              <div style={{ height: 30, width: 45, backgroundColor: 'green', textAlign: 'center' }}>
                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', lineHeight: 2 }}>
                  {this.state.devices.data.filter((item) => {
                    return item.status === 'online'
                  }).length}
                </span>
              </div>
              <div style={{ height: 30, width: 45, backgroundColor: 'blue', textAlign: 'center' }}>
                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', lineHeight: 2 }}>
                  {
                    this.state.positions.data.filter((item) => {
                      return item.attributes.motion === true && this.state.devices.data.filter((item2) => { return item2.id === item.deviceId }).some(obj => obj.status === 'online')
                    }).length}
                </span>
              </div>
              <div style={{ height: 30, width: 45, backgroundColor: 'red', textAlign: 'center' }}>
                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', lineHeight: 2 }}>
                  {this.state.devices.data.filter((item) => {
                    return item.status === 'offline'
                  }).length}
                </span>
              </div>
              <div style={{ height: 30, width: 45, backgroundColor: 'brown', textAlign: 'center' }}>
                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', lineHeight: 2 }}>
                  {this.state.devices.data.length}
                </span>
              </div>
              <div style={{ height: 30, width: 45, backgroundColor: '#000', textAlign: 'center' }}>
                <span style={{ color: '#fff', fontSize: 14, fontWeight: 'bold', lineHeight: 2 }}>
                  {this.state.devices.allData.length}
                </span>
              </div>
              <br />
              <div style={{ height: 30, width: 45, backgroundColor: this.state.showLabels ? 'green' : 'gray', textAlign: 'center', borderRadius: 5, cursor: 'pointer' }}>
                <span style={{ color: '#fff' }} onClick={async () => {

                  await writeString('showLabels', this.state.showLabels ? '0' : '1');
                  this.setState({
                    ...this.state,
                    showLabels: !this.state.showLabels
                  })
                }}>
                  <TagIcon fontSize="small" style={{ color: '#fff', marginTop: 5 }} />
                </span>
              </div>
              <br />
              <div style={{ height: 30, width: 45, backgroundColor: 'red', textAlign: 'center', borderRadius: 5, cursor: 'pointer' }}>
                <span style={{ color: '#fff' }} onClick={async () => {
                  await deleteStorage('session');
                  this.props.history.push({
                    pathname: `/login`
                  });
                }}>
                  <ExitIcon fontSize="small" style={{ color: '#fff', marginTop: 5 }} />
                </span>
              </div>
            </div>
          </Control>

          <Control position="bottomleft" >
            <div style={{ height: 250, width: this.state.page.width }}>
              <DataGrid
                components={{ Toolbar: QuickSearchToolbar }}
                componentsProps={{
                  toolbar: {
                    value: this.state.searchText,
                    onChange: (event) =>
                      this.requestSearch(event.target.value),
                    clearSearch: () => this.contextrequestSearch(''),
                  },
                }}
                rows={this.state.devices.data}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[5]}
                rowHeight={30}
                headerHeight={30}
                style={{
                  backgroundColor: `rgba(255,255,255,0.8)`,
                  color: '#000',
                  fontSize: 12,
                }}
              />
            </div>
          </Control>
          <MarkerClusterGroup>
            {
              this.state.positions.data && this.state.positions.data.map(position => {
                const deviceIdx = this.state.devices.data.findIndex(d => d.id === position.deviceId && d.server === position.server);
                const device = this.state.devices.data[deviceIdx];

                let group_name = '';

                if (device.groupId) {
                  group_name = getGroupNameByDevice(device);
                }

                const device_ignition = position.attributes.ignition !== null ? position.attributes.ignition === true ? 'Sim' : 'Não' : '';
                const device_locked = position.attributes.locked !== null ? position.attributes.locked === true ? 'Sim' : 'Não' : '';

                const device_protocol = position.protocol.toUpperCase();

                //Ajustes Jackson
                const device_alarm = position.attributes.alarm !== null ? position.attributes.alarm : '';
                const device_voltagem = position.attributes.power !== null ? position.attributes.power : '';
                const device_bateria = position.attributes.battery !== null ? position.attributes.battery : '';
                let Dados_alarm = "";
                if (position.attributes.alarm != null) {
                  Dados_alarm = 
                        <Grid item xs={12} justifyContent="center" container wrap>
                          <h3 style={{ color:'#8f0303', textAlign: 'center' }}>{device_alarm}</h3>
                        </Grid>
                }
                return (

                  <Marker icon={getPin(device, position)} key={position.uniqueId} position={[position.latitude, position.longitude]}>
                    <Tooltip direction="right" offset={[25, 15]} opacity={1} permanent={this.state.showLabels}>{device.name}</Tooltip>
                    <Popup closeButton>
                      <Grid container
                        spacing={1}
                        direction="row"
                        justifyContent="flex-start"
                        style={{
                          width: 300,
                        }}
                        alignItems="flex-start">
                        <Grid item xs={12} justifyContent="center">
                          <h3 style={{ textAlign: 'center' }}>{device.name}</h3>
                        </Grid>

{Dados_alarm}

<Tabs defaultIndex={1} justifyContent="center">
  <TabList style={{ textAlign: 'center' }}>
    <Tab>Dados</Tab>
    <Tab>Posição</Tab>
    <Tab>Info</Tab>
    <Tab>Cmd</Tab>
  </TabList>


  <TabPanel>
  <Grid container
spacing={1}
direction="row"
justifyContent="flex-start"
style={{
  
  minHeight:200,
}}
alignItems="flex-start">

<Grid item xs={4} justifyContent="flex-start">
  <span>Servidor</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.server}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Cliente</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{group_name}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Modelo</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.model}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Observação</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.contact}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Num. do Chip</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.phone}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Num. do ICCID</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.icc}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Operad. Chip</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device.operator}</span>
</Grid>
</Grid>

  </TabPanel>


  <TabPanel>
  <Grid container
spacing={1}
direction="row"
justifyContent="flex-start"
style={{
  
  minHeight:200,
}}
alignItems="flex-start">

<Grid item xs={4} justifyContent="flex-start">
  <span>Ignição</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device_ignition}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Velocidade</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{`${this.speedFormatter(position.speed)} Km/h`}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Data Posição</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{moment(position.fixTime).format('DD/MM/YYYY HH:mm:ss')}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Ult. Atualização</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{moment(device.lastUpdate).format('DD/MM/YYYY HH:mm:ss')}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Direção</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{this.courseFormatter(position.course)}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Endereço</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start" container wrap>
<span> <a class="link" href={`https://www.google.com/maps/place/${position.latitude},${position.longitude}/@${position.latitude},${position.longitude},17z/data=!3m1!4b1!4m5!3m4!1s0x0:0x0!8m2!3d,${position.latitude}!4d${position.longitude}`} target="_blank">{position.address}</a></span>
</Grid>                        

<Grid item xs={12} justifyContent="center" container wrap container wrap>
<Button href={`http://maps.google.com/maps?q=&layer=c&cbll=${position.latitude},${position.longitude}`} target="_blank"
style={{ color: 'blue', fontSize: 14 }}>StreetView </Button>
</Grid>

</Grid>
  </TabPanel>

  <TabPanel>
  <Grid container
spacing={1}
direction="row"
justifyContent="flex-start"
style={{
  
  minHeight:200,
}}
alignItems="flex-start">

<Grid item xs={4} justifyContent="flex-start">
  <span>Protocolo</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device_protocol}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Bloqueado</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device_locked}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Voltagem</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device_voltagem}</span>
</Grid>

<Grid item xs={4} justifyContent="flex-start">
  <span>Bateria</span>
</Grid>
<Grid item xs={8} justifyContent="flex-start">
  <span>{device_bateria}</span>
</Grid>
</Grid>
</TabPanel>
<TabPanel>
  <Grid container
spacing={1}
direction="row"
justifyContent="flex-start"
style={{
  
  minHeight:200,
}}
alignItems="flex-start">

<Grid item xs={12} justifyContent="center" container wrap>
  <Button
    variant="contained"
    color="inherit"
    onClick={() => {
      this.handleClickLockDialogOpen('Atenção', 'Deseja realizar bloqueio?', device.server, position.deviceId, "engineStop")
    }}
    style={{ color: 'red', fontSize: 14 }}> Bloqueio </Button>
</Grid>
<Grid item xs={12} justifyContent="center" container wrap>
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      this.handleClickLockDialogOpen('Atenção', 'Deseja realizar desbloqueio?', device.server, position.deviceId, "engineResume")
    }}
    style={{ color: 'green', fontSize: 14 }}>Desbloqueio </Button>
</Grid>

<Grid item xs={12} justifyContent="center" container wrap>
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      this.handleClickLockDialogOpen('Atenção', 'Deseja requisitar posição?', device.server, position.deviceId, "positionSingle")
    }}
    style={{ color: 'blue', fontSize: 14 }}>Requisitar Posição</Button>
</Grid>


<Grid item xs={12} justifyContent="center" container wrap>
  <Button
    variant="contained"
    color="primary"
    onClick={() => {
      this.handleClickLockDialogOpen('Atenção', 'Deseja reiniciar o rastreador?', device.server, position.deviceId, "rebootDevice")
    }}
    style={{ color: 'brown', fontSize: 14 }}>Reiniciar Rastreador </Button>
</Grid>

</Grid>
</TabPanel>
</Tabs>


</Grid>
</Popup>
</Marker>
)
})
}
</MarkerClusterGroup>
</LeafletContainer>
{/** dialog add new server */}
        <Dialog open={this.state.servers.openDialogNew} onClose={this.handleClickNewServerClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Novo Servidor</DialogTitle>
          <Formik
            initialValues={{
              code: '',
              name: '',
              url: '',
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              code: Yup.string()
                .required('Campo requerido'),
              url: Yup.string()
                .required('Campo requerido'),
              username: Yup.string()
                .required('Campo requerido'),
              password: Yup.string()
                .required('Campo requerido'),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(true);

              const result = await insertServer({
                code: values.code,
                name: values.name,
                url: values.url,
                username: values.username,
                password: values.password,
              });

              this.setState({
                ...this.state,
                servers: {
                  data: result,
                  openDialogNew: false
                }
              });

              await this.updateData(true);
            }}>

            {({ errors, touched, values, handleChange, isSubmitting, handleSubmit, handleBlur }) => (
              <Form>
                <form>
                  <DialogContent>
                    <DialogContentText>
                      Informe os dados abaixo:
                    </DialogContentText>

                    <TextField
                      autoFocus
                      margin="dense"
                      id="code"
                      label="Código"
                      type="text"
                      value={values.code}
                      onChange={handleChange}
                      className={errors.code && "input-error"}
                      onBlur={handleBlur}
                      helperText={errors.code && touched.code && errors.code}
                      fullWidth
                    />
                    {errors.code && touched.code ? (
                      <span style={{ color: 'red' }}>{errors.code} <br /></span>
                    ) : null}
                    <TextField
                      margin="dense"
                      id="name"
                      label="Descrição"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      className={errors.name && "input-error"}
                      onBlur={handleBlur}
                      helperText={errors.name && touched.name && errors.name}
                      fullWidth
                    />
                    {errors.password && touched.password ? (
                      <span style={{ color: 'red' }}>{errors.password} <br /></span>
                    ) : null}
                    <TextField
                      margin="dense"
                      id="url"
                      label="URL"
                      type="text"
                      value={values.url}
                      onChange={handleChange}
                      className={errors.url && "input-error"}
                      onBlur={handleBlur}
                      helperText={errors.url && touched.url && errors.url}
                      fullWidth
                    />
                    {errors.url && touched.url ? (
                      <span style={{ color: 'red' }}>{errors.url} <br /></span>
                    ) : null}
                    <TextField
                      margin="dense"
                      id="username"
                      label="Usuário"
                      type="text"
                      value={values.username}
                      onChange={handleChange}
                      className={errors.username && "input-error"}
                      onBlur={handleBlur}
                      helperText={errors.username && touched.username && errors.username}
                      fullWidth
                    />
                    {errors.username && touched.username ? (
                      <span style={{ color: 'red' }}>{errors.username} <br /></span>
                    ) : null}
                    <TextField
                      margin="dense"
                      id="password"
                      label="Senha"
                      type="text"
                      value={values.password}
                      onChange={handleChange}
                      className={errors.password && "input-error"}
                      onBlur={handleBlur}
                      helperText={errors.password && touched.password && errors.password}
                      fullWidth
                    />
                    {errors.password && touched.password ? (
                      <span style={{ color: 'red' }}>{errors.password} <br /></span>
                    ) : null}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.handleClickNewServerClose} color="primary" style={{ fontSize: 16 }}>
                      Cancelar
                    </Button>
                    <Button onClick={handleSubmit} color="primary" style={{ fontSize: 16 }} disabled={isSubmitting}>
                      Adicionar
                    </Button>
                  </DialogActions>
                </form>
              </Form>
            )}
          </Formik>
        </Dialog>

        {/** dialog delete server */}

        <Dialog
          open={this.state.servers.openDialogDelete}
          keepMounted
          onClose={this.handleClickDeleteServerClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{"Deseja apagar este servidor?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Essa ação não poderá ser desfeita.
          </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickDeleteServerClose} color="primary" style={{ fontSize: 16 }} >
              Não
          </Button>
            <Button onClick={this.handleClickDeleteServerExecute} color="primary" style={{ fontSize: 16 }} >
              Sim
          </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogLock.open}
          keepMounted
          onClose={this.handleClickLockDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{this.state.dialogLock.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.dialogLock.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClickLockDialogClose} color="primary">
              Não
          </Button>
            <Button onClick={this.handleClickLockDialogExecute} color="primary">
              Sim
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MapContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(MapContainer));
