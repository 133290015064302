import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
//import { configureStore } from "@reduxjs/toolkit";
import { ThemeProvider } from "@material-ui/core/styles";
//import { Provider } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useTheme } from "./theme";

import { SignIn } from "./SignIn";
import Maps from "./Maps/Maps";

//import { DataProvider } from "./Providers/DataProvider";
//import Driver from "./People/Driver";
//import peopleReducer from "./ReduxTable/peopleSlice";
//import AppBarAndDrawer from "./AppBarAndDrawer/AppBarAndDrawer";
//import { Dashboard } from "./Dashboard/Dashboard";
//import { Home } from "./Home/Home";
//import People from "./ReduxTable/people";
//import Components from "./Components/Components";
//import Settings from "./Settings/Settings";


export default function App() {
  /*const store = configureStore({
    reducer: {
      people: peopleReducer,
    },
  });*/
  const [currentTheme, setCurrentTheme] = useTheme();
  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ThemeProvider theme={currentTheme}>
          <Router>
            <div>

              {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
              <Switch>
                <Route path="/login">
                  <SignIn />
                </Route>
                <Route path="/">
                  <Maps />
                </Route>
                {/*
                <Route path="/profile">
                  <Driver id={1} />
                </Route>
                <Route path="/dashboard">
                      <AppBarAndDrawer
                        currentTheme={currentTheme}
                        setCurrentTheme={setCurrentTheme}
                      />
                      <Home />
                    </Route>
                  */}
              </Switch>
            </div>
          </Router>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </>
  );
}
