import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import MapContainer from "./MapContainer";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    overflow: 'hidden',
    padding:0,
    margin:0,
    left:0,
    top:0
  },
}));

export default function Trips() {
  const classes = useStyles();
  let history = useHistory();

  useEffect(() => {

    (async function () {
      const session = await window.localStorage.getItem('session');
      if (!session) {
        history.push("/login");
      }
    })();

  }, [history]);
  return (
    <div className={classes.root}>
      <MapContainer />
    </div>
  );
}
