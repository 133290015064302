import L from 'leaflet';

const getPin = (device, position) => {

  let status = 'offline'

  if(device.status === 'online' && position.attributes.motion) {
    status = 'motion';
  } else {
    status = device.status;
  }

  let pin = `img/icons/marker_default_${status}.png`;

  if (device.category !== '') {
    pin = `img/icons/marker_${device.category}_${status}.png`;
  }

 return  L.divIcon({
  iconSize: [32, 32],
  iconAnchor: [-0, -0],
  className: 'yourClassName',
  html: `<img 
  style="transform: rotate(${position.course}deg);"
  height="32" 
  width="32" 
  src="${pin}">`
})
}

export default getPin;