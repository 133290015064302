import api from '../api';
import axios from 'axios';

import { readStorage, writeStorage } from '../storage';


export const getServers = async () => {
  let servers = await readStorage('servers');

  if (!servers) {

    servers = [];

    let session = await readStorage('session');

    const user = await api.get(`/users`, {
      headers: {
        'Content-Type': 'application/json'
      },
      auth: {
        username: session.email,
        password: session.password,
      }
    });

    if (user.data[0].attributes.servers !== null) {
      servers = JSON.parse(user.data[0].attributes.servers);
    }

  }

  return servers;
}

export const insertServer = async (values) => {

  let servers = await readStorage('servers');

  if (!servers) {
    servers = [];
  }

  const index = servers.findIndex(s => s.code === values.code);

  if (index === -1) {
    servers.push({ ...values });
    await writeStorage('servers', servers);
    await updateServer({
      ...servers,
      values
    });
  }

  return servers;

}

export const deleteServer = async (code) => {
  let servers = await readStorage('servers');

  const result = servers.filter(function (obj) {
    return obj.code !== code;
  });

  await writeStorage('servers', result);

  await updateServer({
    ...result
  });

  return result;
}


export const updateServer = async (values) => {

  try {
    let session = await readStorage('session');

    const user = await api.get(`/users/${session.id}`, {
      headers: {
        'Content-Type': 'application/json'
      },
      auth: {
        username: session.email,
        password: session.password,
      }
    });

    await api.put(`/users/${session.id}`, {
      ...user.data,
      attributes: {
        ...user.data.attributes,
        servers: JSON.stringify(values),
      }
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      auth: {
        username: session.email,
        password: session.password,
      }
    });

    return true;

  } catch (error) {

    alert('Erro ao gravar no servidor remoto!\n Configurações armazenadas em cache.')

    return false;
  }

}

export const getAllGroups = async () => {
  let servers = await readStorage('servers');
  let groups = [];

  for (let i = 0; i < servers.length; i++) {
    try {

      const { code, url, username, password } = servers[i];
      const result = await axios.get(`${url}/api/groups`, {
        headers: {
          'Content-Type': 'application/json'
        },
        auth: {
          username,
          password,
        }
      });

      if (result.status === 200) {
        for (let j = 0; j < result.data.length; j++) {
          const device = result.data[j];
          groups.push({
            ...device,
            server: code,
          });
        };
      }

    } catch (error) {

    }
  };

  return groups;
}

export const getAllDevices = async () => {
  let servers = await readStorage('servers');
  let devices = [];

  for (let i = 0; i < servers.length; i++) {
    try {

      const { code, url, username, password } = servers[i];
      const result = await axios.get(`${url}/api/devices`, {
        headers: {
          'Content-Type': 'application/json'
        },
        auth: {
          username,
          password,
        }
      });

      if (result.status === 200) {
        for (let j = 0; j < result.data.length; j++) {
          const device = result.data[j];
          devices.push({
            ...device,
            server: code,
          });
        };
      }

    } catch (error) {

    }
  };

  return devices;
}

export const getAllPositions = async () => {
  let servers = await readStorage('servers');
  let positions = [];

  for (let i = 0; i < servers.length; i++) {
    try {

      const { code, url, username, password } = servers[i];

      const result = await axios.get(`${url}/api/positions`, {
        headers: {
          'Content-Type': 'application/json'
        },
        auth: {
          username,
          password,
        }
      });

      if (result.status === 200) {

        for (let j = 0; j < result.data.length; j++) {
          const position = result.data[j];
          positions.push({
            ...position,
            server: code,
          });
        };

      }

    } catch (error) {
      //console.log(error)
    }
  };

  return positions;
}

export const sendCommand = async ({ server, deviceId, command }) => {
  let servers = await readStorage('servers');
  if (!servers) {
    servers = [];
  }

  const index = servers.findIndex(s => s.code === server);

  if (index > -1) {

    try {

      const { url, username, password } = servers[index];
      const result = await axios.post(`${url}/api/commands/send`, {
        attributes: {},
        description: "Novo...",
        deviceId,
        id: 0,
        textChannel: false,
        type: command,
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        auth: {
          username,
          password,
        }
      });

      if (result.status === 200) {
        alert('Comando enviado com sucesso')
      }

      if (result.status === 202) {
        alert('Comando enviado em fila')
      }

    } catch (error) {
      alert(`Houve um problema ao tentar enviar o comando:\n${error}`)
    }

  } else {
    alert('Servidor não encontrado')
  }

}