export const readStorage = async (key) => {
  const result = window.localStorage.getItem(key);
  return !result ? [] : JSON.parse(result);
}

export const writeStorage = async (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
}

export const deleteStorage = async (key) => {
  window.localStorage.removeItem(key);
}


export const readString = async (key) => {
  const result = window.localStorage.getItem(key);
  return !result ? [] : result;
}

export const writeString = async (key, value) => {
  window.localStorage.setItem(key, value);
}